import React from 'react';
import PropTypes from 'prop-types';
import Header from 'cccisd-header';
import headerLogo from '../../pages/StudentLogin/assets/minimal_logo.svg';

const NavBar = ({ className }) => {
    const role = window.cccisd.fortress.user.acting.role.handle;
    const isInstructor = role === 'instructor';

    if (isInstructor) {
        return <Header logo={getInstructorLogo()} className="container-fluid" />;
    }
    return <Header logo="Emotion Explorer" className={className} />;
};

NavBar.defaultProps = {
    className: 'container',
};

NavBar.propTypes = {
    className: PropTypes.string,
};

const getInstructorLogo = () => {
    return <img src={headerLogo} style={{ minHeight: '48px', top: '-55%', position: 'relative' }} alt="" />;
};

export default NavBar;
