import React from 'react';
import PropTypes from 'prop-types';
import PrimaryNav from '../../components/NavBar';
import { Nav as SecondaryNav } from 'cccisd-laravel-appdefs';
import Footer from 'cccisd-footer';
import style from './style.css';

const AppDefs = window.cccisd.appDefs;
const Fortress = window.cccisd.fortress;

const MainLayout = ({ children, className = 'container', showSecondaryNav }) => {
    return (
        <div className={style.wrapper}>
            <div className={style.header}>
                <PrimaryNav className={className} />
            </div>
            <div className={style.body}>
                {showSecondaryNav && Fortress.auth() && (
                    <SecondaryNav
                        className={className}
                        navs={AppDefs.navs}
                        routes={AppDefs.routes}
                    />
                )}
                <div className={className}>{children}</div>
            </div>

            <div className={style.footer}>
                <Footer className={className} />
            </div>
        </div>
    );
};

MainLayout.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    showSecondaryNav: PropTypes.bool,
};

export default MainLayout;
