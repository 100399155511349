import React from 'react';
import IconCopy from 'cccisd-icons/copy3';
import Tooltip from 'cccisd-tooltip';
import { copyOnClick } from '../../helpers';
import style from './style.css';

const Env = window.cccisd.env;

export default settings => {
    return ({ value, isCsv }) => {
        if (!value) {
            return <span className={style.cell}>-</span>;
        }

        const baseUrl = Env.url;
        const loginUrl = baseUrl.endsWith('/') ? baseUrl + 'student/' + value : baseUrl + '/student/' + value;

        if (isCsv) {
            return loginUrl;
        }
        return (
            <span className={style.cell}>
                <Tooltip
                    title={
                        <>
                            <h5>Click to copy link:</h5>
                            <p className={style.tooltipLink}>{loginUrl}</p>
                        </>
                    }
                >
                    <button type="button" className="btn btn-xs btn-info" onClick={() => copyOnClick(loginUrl)}>
                        <IconCopy />
                    </button>
                </Tooltip>
            </span>
        );
    };
};
