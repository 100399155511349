import React, { useState, useEffect } from 'react';
import style from './style.css';
import IconPlay from 'cccisd-icons/play4';
import IconPause from 'cccisd-icons/pause2';
import IconReplay from 'cccisd-icons/reset';
import IconMute from 'cccisd-icons/volume-mute';
import IconUnmute from 'cccisd-icons/volume-high';
import { Howl, Howler } from 'howler';
import notification from 'cccisd-notification';

const AudioControls = props => {
    const [isMute, setIsMute] = useState(false);
    const [sound, setSound] = useState();
    const [isPlaying, setIsPlaying] = useState(false);

    useEffect(() => {
        setSound(
            new Howl({
                src: [props.audio],
                autoplay: props.autoplay,
                preload: true,
                onloaderror(id, msg) {
                    notification({
                        message: 'Error loading audio: ' + msg,
                        type: 'danger',
                    });
                },
                onplayerror(id, msg) {
                    notification({
                        message: 'Error playing audio: ' + msg,
                        type: 'danger',
                    });
                },
                onend(id) {
                    setIsPlaying(false);
                },
            })
        );

        if (props.autoplay) {
            setIsPlaying(true);
        }

        return function cleanup() {
            Howler.stop();
        };
    }, []);

    function onMuteClicked() {
        sound.mute(!isMute);
        setIsMute(!isMute);
    }

    function onPlayClicked() {
        if (sound.playing()) {
            sound.pause();
        } else {
            sound.play();
        }
        setIsPlaying(sound.playing());
    }

    function onReplayClicked() {
        sound.stop();
        sound.play();
        setIsPlaying(sound.playing());
    }

    return (
        <div className={style.audio_buttons_container}>
            <button
                type="button"
                className="btn btn-secondary btn-lg"
                style={{ minWidth: '75px', backgroundColor: 'lightsteelblue' }}
                onClick={e => {
                    onPlayClicked();
                }}
            >
                {isPlaying ? <IconPause /> : <IconPlay />}
            </button>
            <button
                type="button"
                className="btn btn-secondary btn-lg"
                style={{ minWidth: '75px', backgroundColor: 'lightsteelblue' }}
                onClick={e => {
                    onReplayClicked();
                }}
            >
                <IconReplay />
            </button>
            <button
                type="button"
                className="btn btn-secondary btn-lg"
                style={{ minWidth: '75px', backgroundColor: 'lightsteelblue' }}
                onClick={e => {
                    onMuteClicked();
                }}
            >
                {isMute && <IconMute />}
                {!isMute && <IconUnmute />}
            </button>
        </div>
    );
};

export default AudioControls;
