import React, { useState, useRef, useEffect } from 'react';
import style from './style.css';
import safariPoster from '../Assets/Placeholder_SafariPoster.svg';
import safariBookshelf from '../Assets/Safari Bookshelf.svg';
import circusPoster from '../Assets/Placeholder_CircusPoster.svg';
import circusBookshelf from '../Assets/Circus Shelf.svg';
import beePoster from '../Assets/Placeholder_BeePoster.svg';
import beeBookshelf from '../Assets/Honeycomb Shelf.svg';
import beeBookshelfFront from '../Assets/Honeycomb Shelf Top.svg';
import circusBookshelfMask from '../Assets/Circus Shelf_Top Mask.svg';
import corkboardCorner from '../Assets/DB_Corkboard_tmp.png';
import beesPosterImg from '../Assets/Posters/Posters0010.png';
import beesPosterImgText from '../Assets/Posters/Posters0002.png';
import circusPosterImg from '../Assets/Posters/Posters0011.png';
import circusPosterImgText from '../Assets/Posters/Posters0005.png';
import safariPosterImg from '../Assets/Posters/Posters0012.png';
import safariPosterImgText from '../Assets/Posters/Posters0008.png';
import { Player, DefaultPlayerSettingsContext } from 'cccisd-laravel-flow';
import useEENavigation from './useEENavigation';
import Loader from 'cccisd-loader';
import Modal from 'cccisd-modal';
import IconExit from 'cccisd-icons/enter4';
import axios from 'cccisd-axios';
import _get from 'lodash/get';
import notification from 'cccisd-notification';
import AudioControls from './audioControls';
import beeAudio from '../Assets/Audio/meet the XEL Bees.mp3';
import safariAudio from '../Assets/Audio/Meet The EL Safari Characters.mp3';
import circusAudio from '../Assets/Audio/BirdDogCircusStars_KayeWeaver.mp3';
import tablet from '../Assets/Activities/Help Videos.svg';
import activitiesImg from '../Assets/Activities/Activities.svg';
import calmingImg from '../Assets/Activities/Calming.svg';
import coloringImg from '../Assets/Activities/Coloring.svg';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import getBulletinBoardDeploymentIdQuery from './getBulletinBoardDeploymentId.graphql';
import { client } from 'cccisd-apollo';
import { ProducerPlayer } from 'cccisd-video-player';

const EEStudentDashboard = props => {
    const [bookPlayerVisible, setBookPlayerVisible] = useState(false);
    const role = window.cccisd.fortress.user.acting.role.handle;
    const isLearner = role === 'learner';
    const studentClassCode = getStudentClassCode();
    const [beePosterOpenCount, setBeePosterOpenCount] = useState(0);
    const [safariPosterOpenCount, setSafariPosterOpenCount] = useState(0);
    const [circusPosterOpenCount, setCircusPosterOpenCount] = useState(0);
    const [anonPawnId, setAnonPawnId] = useState(0);
    const [anonPawnHash, setAnonPawnHash] = useState('');
    const [anonPawnRespondentHash, setAnonPawnRespondentHash] = useState('');
    const [bulletinboardDeploymentId, setBulletinboardDeploymentId] = useState(-1);
    const [tutorialVideoID, setTutorialVideoID] = useState(-1);

    const isFirstLogin = useRef(false);
    const haveNotStartedCircusBooks = useRef(false);
    const haveNotStartedSafariBooks = useRef(false);
    const haveNotStartedBeeBooks = useRef(false);

    const autoplayVideoModalTriggerRef = useRef();
    const finishedSettingProgressFlgas = useRef(false);

    let { navItems, currentFlowProps, setCurrentFlowHandle, checkBookProgress } = useEENavigation({
        ...props,
        mustTakeInOrder: false,
        enablePrintMode: false,
    });

    useEffect(() => {
        getBulletinBoardDeploymentId();
    }, [currentFlowProps]);

    // reads the progress of the student, and autoplays the dashboard instructional video if no books have been started before
    useEffect(() => {
        if (isLearner && !finishedSettingProgressFlgas.current) {
            updateUserProgressFlags();
        } else if (finishedSettingProgressFlgas.current && isFirstLogin.current) {
            startAutoplayTutorial(-1);
        }
    });

    async function startAutoplayTutorial(ordinalPosition) {
        if (ordinalPosition === -1) {
            // temp patch attempt for video not being playable on android tablet
            await new Promise(r => setTimeout(r, 750));

            setTutorialVideoID(16084);
            isFirstLogin.current = false;
        } else if (ordinalPosition >= 1 && ordinalPosition <= 6) {
            haveNotStartedCircusBooks.current = false;
            setTutorialVideoID(16085);
        } else if (ordinalPosition >= 7 && ordinalPosition <= 12) {
            haveNotStartedSafariBooks.current = false;
            setTutorialVideoID(16086);
        } else if (ordinalPosition >= 13 && ordinalPosition <= 18) {
            haveNotStartedBeeBooks.current = false;
            setTutorialVideoID(16087);
        }

        let modalAlreadyOpened = autoplayVideoModalTriggerRef.current.state.isOpen;
        if (!modalAlreadyOpened) {
            autoplayVideoModalTriggerRef.current.open();
        }
    }

    function haveNotStarted(ordinalPosition) {
        if (ordinalPosition >= 1 && ordinalPosition <= 6) {
            return haveNotStartedCircusBooks.current;
        }
        if (ordinalPosition >= 7 && ordinalPosition <= 12) {
            return haveNotStartedSafariBooks.current;
        }
        if (ordinalPosition >= 13 && ordinalPosition <= 18) {
            return haveNotStartedBeeBooks.current;
        }
        return false;
    }

    function updateUserProgressFlags() {
        let circusFlag = true;
        let safariFlag = true;
        let beeFlag = true;
        let firstLoginFlag = true;

        props.flowList.forEach(navItem => {
            let ordinal = navItem.ordinalPosition;
            if (navItem.isStarted) {
                if (ordinal >= 1 && ordinal <= 6) {
                    circusFlag = false;
                }
                if (ordinal >= 7 && ordinal <= 12) {
                    safariFlag = false;
                }
                if (ordinal >= 13 && ordinal <= 18) {
                    beeFlag = false;
                }
                firstLoginFlag = false;
            }
        });

        isFirstLogin.current = firstLoginFlag;
        haveNotStartedCircusBooks.current = circusFlag;
        haveNotStartedSafariBooks.current = safariFlag;
        haveNotStartedBeeBooks.current = beeFlag;

        finishedSettingProgressFlgas.current = true;
    }

    async function getBulletinBoardDeploymentId() {
        const response = await client.query({
            query: getBulletinBoardDeploymentIdQuery,
            fetchPolicy: 'network-only',
        });

        const queryResults = _get(response, 'data', []) || [];
        if ('deployments' in queryResults) {
            setBulletinboardDeploymentId(queryResults.deployments.deployment.deploymentId);
        }
    }

    function renderBooks(startIndex, endIndex) {
        let indexes = [];
        let start = startIndex;
        while (start < endIndex) {
            indexes.push(start);
            start += 1;
        }
        indexes.push(start);

        let availableBooks = navItems
            .filter(navItem => navItem.ordinalPosition >= startIndex && navItem.ordinalPosition <= endIndex)
            .map(navItem => {
                indexes = indexes.filter(e => e !== navItem.ordinalPosition);
                let isCompleted = navItem.isCompleted;
                let styleApplied = style[`book${navItem.ordinalPosition}`];
                if (isCompleted) {
                    styleApplied = style[`book${navItem.ordinalPosition}_completed`];
                }

                return (
                    <div key={navItem.handle}>
                        <div
                            className={styleApplied}
                            onClick={e => {
                                let ordinal = navItem.ordinalPosition;
                                if (isLearner && haveNotStarted(ordinal)) {
                                    startAutoplayTutorial(ordinal);
                                } else {
                                    setBookPlayerVisible(!bookPlayerVisible);
                                    navItem.setCurrent();
                                }
                            }}
                        />
                    </div>
                );
            });

        let unavailableBooks = indexes.map(e => (
            <div key={e}>
                <div className={style[`book${e}_unavailable`]} />
            </div>
        ));

        return [...availableBooks, ...unavailableBooks];
    }

    async function logout() {
        const response = await axios.get(window.cccisd.boilerplate.route('api.nexus.logout'), {});

        const status = _get(response, 'status', 0);

        if (status === 200) {
            let url = _get(response, 'request.responseURL');
            if (studentClassCode === -1) {
                window.location = url;
            } else if (url.endsWith('/')) {
                window.location = url + 'student/' + studentClassCode;
            } else {
                window.location = url + '/student/' + studentClassCode;
            }
        } else {
            notification({
                message: 'Logout unsuccessful. Error code: ' + status,
                type: 'danger',
            });
        }
    }

    async function createNewAnonymousLearner() {
        let response = await axios.post(
            window.cccisd.boilerplate.route('api.assignmentDeployment.addDeploymentAnon', {
                deploymentId: bulletinboardDeploymentId,
            })
        );

        const responsePawn = _get(response, 'data.data');
        if (responsePawn && responsePawn.id) {
            setAnonPawnId(responsePawn.id);
            setAnonPawnHash(responsePawn.random_hash);
            setAnonPawnRespondentHash(responsePawn.respondent_hash);
        }
    }

    function getStudentClassCode() {
        let user = window.cccisd.fortress.user;
        let code = -1;
        if ('acting' in user && user.acting.data_type === 'learner') {
            code = user.acting.group.type_data.classCode;
        } else if ('actual' in user && user.actual.data_type === 'learner') {
            code = user.actual.group.type_data.classCode;
        }
        return code;
    }

    if (navItems.length === 0 && bulletinboardDeploymentId === -1) {
        return (
            <div className={style.loadingBooks}>
                <Loader type="inline" loading />
            </div>
        );
    }

    return (
        <>
            <div className={style.main}>
                {/* safari group */}
                <div className={isLearner ? style.safari : style.safari_instructor}>
                    <img src={safariPoster} className={style.safari_poster_image} alt="" />
                    <Modal
                        trigger={
                            <img
                                src={safariPosterImg}
                                className={style.safari_poster_image_img}
                                alt=""
                                onClick={e => {
                                    setSafariPosterOpenCount(safariPosterOpenCount + 1);
                                }}
                            />
                        }
                        title={<AudioControls audio={safariAudio} autoplay={safariPosterOpenCount === 1} />}
                        size="large"
                    >
                        <img src={safariPosterImgText} className={style.circus_poster_img_text} alt="" />
                    </Modal>
                    <img src={safariBookshelf} className={style.safari_bookshelf} alt="" />

                    {renderBooks(7, 12)}
                </div>

                {/* circus group */}
                <div className={isLearner ? style.circus : style.circus_instructor}>
                    <img src={circusBookshelf} className={style.circus_bookshelf} alt="" />
                    {renderBooks(1, 1)}
                    <img src={circusBookshelfMask} className={style.circus_bookshelf_mask} alt="" />

                    {renderBooks(2, 6)}

                    <img src={circusPoster} className={style.circus_poster_image} alt="" />
                    <Modal
                        trigger={
                            <img
                                src={circusPosterImg}
                                className={style.circus_poster_image_img}
                                alt=""
                                onClick={e => {
                                    setCircusPosterOpenCount(circusPosterOpenCount + 1);
                                }}
                            />
                        }
                        title={<AudioControls audio={circusAudio} autoplay={circusPosterOpenCount === 1} />}
                        size="large"
                    >
                        <img src={circusPosterImgText} className={style.circus_poster_img_text} alt="" />
                    </Modal>
                </div>

                {/* bee group */}
                <div className={style.bee}>
                    <img src={beeBookshelf} className={style.bee_bookshelf} alt="" />
                    {renderBooks(13, 18)}
                    <img src={beeBookshelfFront} className={style.bee_bookshelf_mask} alt="" />
                    <img src={beePoster} className={style.bee_poster_image} alt="" />
                    <div className={style.flex_justtify_center}>
                        <Modal
                            trigger={
                                <img
                                    src={beesPosterImg}
                                    className={style.bee_poster_image_img}
                                    alt=""
                                    onClick={e => {
                                        setBeePosterOpenCount(beePosterOpenCount + 1);
                                    }}
                                />
                            }
                            title={<AudioControls audio={beeAudio} autoplay={beePosterOpenCount === 1} />}
                            size="large"
                        >
                            <img src={beesPosterImgText} className={style.circus_poster_img_text} alt="" />
                        </Modal>
                    </div>
                </div>

                {/* tablet modal */}
                <Modal
                    trigger={
                        <img
                            src={tablet}
                            className={style.tablet}
                            alt=""
                            onClick={e => {
                                createNewAnonymousLearner();
                            }}
                        />
                    }
                    size="large"
                    title="Help Videos"
                    render={({ closeModal }) => (
                        <DeploymentPlayer
                            deploymentHandle="eeinstructionvideos"
                            disableLayout
                            pawnId={anonPawnId}
                            pawnHash={anonPawnHash}
                            pawnRespondentHash={anonPawnRespondentHash}
                            onComplete={e => {
                                closeModal();
                            }}
                        />
                    )}
                />

                {/* auto-playing video modal */}
                <Modal
                    size="67vw"
                    title="Emotion Explorer Tutorial"
                    ref={autoplayVideoModalTriggerRef}
                    render={() => <ProducerPlayer id={tutorialVideoID} autoplay />}
                />

                {/* corkboard modal */}
                <Modal
                    trigger={
                        <div className={style.corkboard}>
                            <img
                                src={corkboardCorner}
                                className={style.corkboard_corner}
                                alt=""
                                onClick={e => {
                                    createNewAnonymousLearner();
                                }}
                            />
                            <img src={activitiesImg} className={style.resource_1} alt="" />
                            <img src={calmingImg} className={style.resource_2} alt="" />
                            <img src={coloringImg} className={style.resource_3} alt="" />
                        </div>
                    }
                    size="large"
                    title="Activities"
                    render={({ closeModal }) => (
                        <DeploymentPlayer
                            deploymentHandle="bulletinboard"
                            disableLayout
                            pawnId={anonPawnId}
                            pawnHash={anonPawnHash}
                            pawnRespondentHash={anonPawnRespondentHash}
                            onComplete={e => {
                                closeModal();
                            }}
                        />
                    )}
                />

                {currentFlowProps && (
                    <div className={style.player_modal} hidden={!bookPlayerVisible}>
                        <>
                            <div className={style.flex_container} />
                            <div className={style.full_player_container} onClick={e => e.stopPropagation()}>
                                <DefaultPlayerSettingsContext.Provider
                                    value={{
                                        background: 'solidColor',
                                        backgroundColor: '#fff',
                                    }}
                                >
                                    <Player
                                        key={currentFlowProps.handle}
                                        {...currentFlowProps}
                                        isNetworkError={props.isNetworkError}
                                    />
                                </DefaultPlayerSettingsContext.Provider>
                            </div>
                            <button
                                type="button"
                                className={style.player_exit_button_anchor}
                                onClick={e => {
                                    // pass flow handle as argument so it persists
                                    // even after currentFlowHandle gets reset
                                    checkBookProgress(currentFlowProps.handle);
                                    setBookPlayerVisible(false);
                                    setCurrentFlowHandle('');
                                }}
                            >
                                <h1 style={{ margin: '0' }}>
                                    <i className="glyphicon glyphicon-remove-sign" style={{ color: 'red' }} />
                                </h1>
                            </button>
                        </>
                    </div>
                )}
            </div>
            {isLearner && (
                <button
                    type="button"
                    className="btn btn-danger btn-lg"
                    style={{ position: 'absolute', top: '3%', right: '2%' }}
                    onClick={e => {
                        logout();
                    }}
                >
                    <IconExit />
                    <> Logout</>
                </button>
            )}
        </>
    );
};

export default EEStudentDashboard;
