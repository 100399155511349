import React from 'react';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';

const StudentDashboard = props => {
    return (
        <>
            <DeploymentPlayer deploymentHandle="main" />
        </>
    );
};

export default StudentDashboard;
