import thumbnail from './thumbnail.png';
import EEStudentDashboard from './Mediator';

export default {
    handle: 'eeStudentDashboard',
    label: 'EE Student Dashboard',
    thumbnail,
    mediatorComponent: EEStudentDashboard,
    settingsComponent: null,
};
