import React, { useEffect, useState } from 'react';
import { Progress } from 'cccisd-laravel-assignment';
import getInstructorAssignmentPlansQuery from '../../components/EEStudentDashboard/Mediator/getInstructorAssignmentPlans.graphql';
import _get from 'lodash/get';
import { client } from 'cccisd-apollo';
import { DropdownList } from 'react-widgets';
import Modal from 'cccisd-modal';
import CccisdToggle from 'cccisd-toggle';
import axios from 'cccisd-axios';
import notification from 'cccisd-notification';
import PropTypes from 'prop-types';
import { copyOnClick } from '../../helpers';
import Tooltip from 'cccisd-tooltip';
import style from './style.css';
import { isEmpty } from 'lodash';

const ProgressView = props => {
    const [classNames, setClassNames] = useState([]);
    const [pawnIdWhitelist, setPawnIdWhitelist] = useState([]);
    const [classSelected, setClassSelected] = useState('');
    const [classesData, setClassesData] = useState([]);
    const [projectId, setProjectId] = useState(-1);
    const [surveyHandleWhitelist, setSurveyHandleWhitelist] = useState([]);
    const [allSurveyHandles, setAllSurveyHandles] = useState([]);
    const [sessionId, setSessionId] = useState(-1);
    const [surveyHandlesAndNames, setSurveyHandlesAndNames] = useState([]);

    const [updatedClassesData, setUpdatedClassesData] = useState([]);
    const [updatedSurveyHandleWhitelist, setUpdatedSurveyHandleWhitelist] = useState([]);
    const [assignmentsUpdated, setAssignmentsUpdated] = useState(false);

    const [progressDeploymentId, setProgressDeploymentId] = useState();
    const [progressAssignmentId, setProgressAssignmentId] = useState();

    const [classCode, setClassCode] = useState('');
    const [loading, setLoading] = useState(true);

    const ordinalBookNames = {
        'Happy (K)': 1,
        'Sad (K)': 5,
        'Mad (K)': 3,
        'Proud (K)': 4,
        'Sorry (K)': 6,
        'Lonely (K)': 2,
        'Joyful (1)': 10,
        'Upset (1)': 12,
        'Angry (1)': 11,
        'Confident (1)': 8,
        'Guilty (1)': 9,
        'Left Out (1)': 7,
        'Cheerful (2)': 13,
        'Down (2)': 14,
        'Frustrated (2)': 16,
        'Self-Respect (2)': 18,
        'Embarrassed (2)': 15,
        'Isolated (2)': 17,
    };

    // Load initial data from InstructorAssignmentPlans Query
    useEffect(() => {
        async function getLearnerFlowWhiteList() {
            const response = await client.query({
                query: getInstructorAssignmentPlansQuery,
                fetchPolicy: 'network-only',
            });

            const queryResults = _get(response, 'data', []) || [];

            const classes = queryResults.groups.classList;
            setClassesData(classes);

            setClassCode(!isEmpty(classes) ? queryResults.groups.classList[0].fields.classCode : '');

            setUpdatedClassesData(classes);

            setAllSurveyHandles(
                queryResults.deployments.deployment.assignment.surveyList.map(e => {
                    return e.surveyHandle;
                })
            );

            setSurveyHandlesAndNames(
                queryResults.deployments.deployment.assignment.surveyList.map(e => {
                    return e;
                })
            );

            setProgressDeploymentId(queryResults.deployments.deployment.deploymentId);
            setProgressAssignmentId(queryResults.deployments.deployment.assignment.assignmentId);

            const allClassNames = classes.map(e => {
                return e.group.label;
            });

            setProjectId(queryResults.deployments.deployment.assignment.groupId);
            setClassNames(allClassNames);

            setClassSelected(!isEmpty(allClassNames) ? allClassNames[0] : '');
            setLoading(false);
        }

        getLearnerFlowWhiteList();
    }, []);

    // Any time that the classSelected state variable changes, update pawnId and surveyHandle whitelists
    useEffect(() => {
        if (classesData.length > 0 && classSelected !== '') {
            let classPawnIds = getClassPawnIDs();
            setPawnIdWhitelist(classPawnIds);
            setSurveyHandleWhitelist(getClassSurveyHandles());
            setUpdatedSurveyHandleWhitelist(getClassSurveyHandles());
            setSessionId(getSelectedClassData()[0].createdAssignmentPlan.session.sessionId);
            setClassCode(getSelectedClassData()[0].fields.classCode);
        }
    }, [classSelected]);

    // A search to check if a survey is enabled for the currently selected class
    function isSurveyInClass(surveyHandle) {
        return updatedSurveyHandleWhitelist.includes(surveyHandle);
    }

    function getSelectedClassData() {
        let selectedClassData = classesData.filter(e => {
            return e.group.label === classSelected;
        });
        return selectedClassData;
    }

    // Adds a survey to the list of available surveys for the selected class
    function addSurveyHandleToClass(surveyHandle) {
        let allSurveys = getClassSurveyHandles();

        if (!allSurveys.includes(surveyHandle)) {
            let classListCopy = classesData;

            classListCopy = classListCopy.map(e => {
                if (e.group.label === classSelected) {
                    e.createdAssignmentPlan.session.settings.options.push(surveyHandle);
                }
                return e;
            });

            setAssignmentsUpdated(true);
            setUpdatedClassesData(JSONCopy(classListCopy));
            setUpdatedSurveyHandleWhitelist(getClassSurveyHandles());
        } else {
            removeSurveyHandleFromClass(surveyHandle);
        }
    }

    // Removes a survey from the list of available surveys for the selected class
    function removeSurveyHandleFromClass(surveyHandle) {
        let allSurveys = getClassSurveyHandles().filter(e => {
            return e !== surveyHandle;
        });

        let classListCopy = classesData;

        classListCopy = classListCopy.map(e => {
            if (e.group.label === classSelected) {
                e.createdAssignmentPlan.session.settings.options = allSurveys;
            }
            return e;
        });

        setAssignmentsUpdated(true);
        setUpdatedClassesData(JSONCopy(classListCopy));
        setUpdatedSurveyHandleWhitelist(getClassSurveyHandles());
    }

    // Returns the list of all availble surveys for the selected class
    function getClassSurveyHandles() {
        let selectedClassData = getSelectedClassData();

        if (selectedClassData.length > 0) {
            return selectedClassData[0].createdAssignmentPlan.session.settings.options;
        }
        return [];
    }

    function getSurveyNameBasedOnHandle(sHandle) {
        if (surveyHandlesAndNames.length > 0) {
            for (let i = 0; i < surveyHandlesAndNames.length; i++) {
                let item = surveyHandlesAndNames[i];
                let match = item.surveyHandle === sHandle;
                if (match) {
                    let j = 0;
                    for (const [bookName] of Object.entries(ordinalBookNames)) {
                        if (j === i) {
                            return bookName;
                        }
                        j += 1;
                    }
                }
            }
        }
    }

    // Returns the list of all pawn ids for the selected class
    function getClassPawnIDs() {
        let classPawnIds = getSelectedClassData();

        classPawnIds = classPawnIds[0].descendantRoles.learnerList.map(e => {
            return e.pawn.pawnId;
        });

        return classPawnIds;
    }

    function JSONCopy(source) {
        return JSON.parse(JSON.stringify(source));
    }

    // save any changes to the surveys assigned to the currently selected class
    async function save(closeModal) {
        if (assignmentsUpdated) {
            setAssignmentsUpdated(false);

            const response = await axios.put(
                window.cccisd.boilerplate.route('api.assignmentPlanSession.update', {
                    sessionId,
                }),
                {
                    settings: {
                        options: getClassSurveyHandles(),
                    },
                }
            );

            if (response.status === 200) {
                setClassesData(JSONCopy(updatedClassesData));
                setSurveyHandleWhitelist(JSONCopy(updatedSurveyHandleWhitelist));
                showSuccessNotification();
                closeModal();
            } else {
                showDangerNotification(response.status);
            }
        } else {
            showInfoNotification();
        }
    }

    function showSuccessNotification() {
        notification({
            message: 'Saved',
            duration: 2000,
            type: 'success',
        });
    }

    function showInfoNotification() {
        notification({
            message: 'No Changes to Save',
            duration: 2000,
            type: 'info',
        });
    }

    function showDangerNotification(errorCode) {
        notification({ message: 'Error ' + errorCode, type: 'danger' });
    }

    const Env = window.cccisd.env;
    const baseUrl = Env.url;
    const loginUrl = baseUrl.endsWith('/') ? baseUrl + 'student/' + classCode : baseUrl + '/student/' + classCode;

    if (isEmpty(classesData) && !loading) {
        return (
            <div className={style.setup_instructions}>
                <h4>Welcome!</h4>
                <h5>To get started, please follow these steps:</h5>
                <ol>
                    <li>
                        Navigate to the <a href={window.cccisd.env.url + '/manage#class'}>Manage Tab.</a>
                    </li>
                    <li>
                        Add a class by clicking the {'"Add Class"'} button in the Classes section of the Manage area.
                    </li>
                    <li>
                        Once a class is created, navigate to the{' '}
                        <a href={window.cccisd.env.url + '/manage#learner'}>Learners</a> section of the Manage area.
                    </li>
                    <li>
                        Add a learner by clicking the {'"Add Learner"'} button in the Learners section of the Manage
                        area.
                    </li>
                    <li>
                        Done! Student assignments and progress will now appear in the{' '}
                        <a href={window.cccisd.env.url + '/progress'}>Progress Tab.</a>
                    </li>
                </ol>
            </div>
        );
    }
    return (
        <>
            {projectId !== -1 && (
                <div className={style.ui_grid_container}>
                    <div>
                        <h4>Class</h4>
                        <div className={style.width_15_vw}>
                            <DropdownList
                                data={classNames}
                                value={classSelected}
                                onChange={e => {
                                    setClassSelected(e);
                                }}
                            />
                        </div>
                        {!isEmpty(pawnIdWhitelist) && (
                            <Modal
                                trigger={
                                    <>
                                        <div style={{ minHeight: '27px' }} />
                                        <input className={style.assign_button} type="submit" value="" />
                                    </>
                                }
                                title={classSelected}
                                size="large"
                            >
                                <>
                                    <div
                                        style={{
                                            display: 'grid',
                                            gridRowGap: '0.5em',
                                            gridTemplateColumns: '1fr 1fr 1fr',
                                            gridAutoFlow: 'column',
                                            gridTemplateRows: 'auto auto auto auto auto auto',
                                        }}
                                    >
                                        {allSurveyHandles.map((surveyHandleName, index) => {
                                            let ordinalSurveyHandleName = surveyHandleName;
                                            let j = 0;
                                            for (const [, bookOrdinalIndex] of Object.entries(ordinalBookNames)) {
                                                if (index === j) {
                                                    ordinalSurveyHandleName = allSurveyHandles[bookOrdinalIndex - 1];
                                                    break;
                                                }
                                                j += 1;
                                            }

                                            return (
                                                <CccisdToggle
                                                    key={surveyHandleName}
                                                    label={getSurveyNameBasedOnHandle(surveyHandleName)}
                                                    value={isSurveyInClass(ordinalSurveyHandleName)}
                                                    onChange={e => {
                                                        addSurveyHandleToClass(ordinalSurveyHandleName);
                                                    }}
                                                />
                                            );
                                        })}
                                    </div>
                                </>
                                <SaveButton save={save} />
                            </Modal>
                        )}
                    </div>
                    {!isEmpty(pawnIdWhitelist) && (
                        <div
                            style={{
                                display: 'flex',
                                gap: '0.5em',
                                flexWrap: 'wrap',
                            }}
                        >
                            <div className={style.panel}>
                                <div className={style.copy_panel_grid}>
                                    <div className={style.button_container}>
                                        <Tooltip
                                            title={
                                                <>
                                                    <h5>Click to copy link:</h5>
                                                    <p>{loginUrl}</p>
                                                </>
                                            }
                                        >
                                            <input
                                                className={style.copy_button}
                                                type="submit"
                                                value=""
                                                onClick={() => copyOnClick(loginUrl)}
                                            />
                                        </Tooltip>
                                    </div>
                                    <div>
                                        <h3 className={style.text_align_center}>{classSelected}: Student Access URL</h3>
                                        <div className={style.url_text}>{loginUrl}</div>
                                        <div className={style.text_description}>
                                            Copy the link above and share it with your students in this class to give
                                            them access to the Student Dashboard.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={style.panel}>
                                <div className={style.resource_buttons_container}>
                                    <div className={style.individual_resource_button_container}>
                                        <input
                                            className={style.introducing_button}
                                            type="submit"
                                            value=""
                                            onClick={async () => {
                                                const result = await fetch(
                                                    'https://cccisd-public.s3.amazonaws.com/games/emotionExplorer/DASHBOARD_ASSETS/Introducing Students.pdf',
                                                    { method: 'HEAD' }
                                                );
                                                if (result.status === 200) {
                                                    window.open(
                                                        'https://cccisd-public.s3.amazonaws.com/games/emotionExplorer/DASHBOARD_ASSETS/Introducing Students.pdf'
                                                    );
                                                }
                                            }}
                                        />
                                        <h4 className={style.text_align_center}>
                                            Introducing
                                            <br />
                                            Students
                                        </h4>
                                    </div>
                                    <div className={style.individual_resource_button_container}>
                                        <input
                                            className={style.ebook_button}
                                            type="submit"
                                            value=""
                                            onClick={() => {
                                                window.open(
                                                    'https://cccisd-public.s3.amazonaws.com/games/emotionExplorer/DASHBOARD_ASSETS/Book Descriptions All.pdf'
                                                );
                                            }}
                                        />
                                        <h4 className={style.text_align_center}>
                                            e-Book
                                            <br />
                                            Descriptions
                                        </h4>
                                    </div>
                                    <div className={style.individual_resource_button_container}>
                                        <input
                                            className={style.completion_button}
                                            type="submit"
                                            value=""
                                            onClick={() => {
                                                window.open(
                                                    'https://cccisd-public.s3.amazonaws.com/games/emotionExplorer/DASHBOARD_ASSETS/CompletionCertificate.pdf'
                                                );
                                            }}
                                        />
                                        <h4 className={style.text_align_center}>
                                            Completion <br />
                                            Certificate
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {isEmpty(pawnIdWhitelist) && !loading && (
                        <td>
                            <div className={style.secondary_instructions}>
                                <h4>To finish setting up, please follow these steps:</h4>
                                <ol>
                                    <li>
                                        Navigate to the <a href={window.cccisd.env.url + '/manage#learner'}>Learners</a>{' '}
                                        section of the Manage area.
                                    </li>
                                    <li>
                                        Add a learner by clicking the {'"Add Learner"'} button in the Learners section
                                        of the Manage area.
                                    </li>
                                    <li>
                                        Done! Student assignments and progress will now appear in the{' '}
                                        <a href={window.cccisd.env.url + '/progress'}>Progress Tab.</a>
                                    </li>
                                </ol>
                            </div>
                        </td>
                    )}
                </div>
            )}

            {projectId !== -1 && !isEmpty(pawnIdWhitelist) && (
                <>
                    <br />
                    <br />
                    <Progress
                        deploymentId={progressDeploymentId}
                        hideSystemId
                        projectId={projectId}
                        assignmentId={progressAssignmentId}
                        hideDeploymentDropdown
                        hideProjectDropdown
                        hideQuestDropdown
                        pawnIdWhitelist={pawnIdWhitelist}
                        showByWhitelist={['survey']}
                        surveyHandleWhitelist={surveyHandleWhitelist}
                    />
                </>
            )}
        </>
    );
};

class SaveButton extends React.Component {
    static propTypes = {
        closeModal: PropTypes.func,
        save: PropTypes.func,
    };

    render() {
        return (
            <>
                <div
                    style={{
                        display: 'grid',
                        gridRowGap: '0.5em',
                        justifyContent: 'end',
                        marginTop: '20px',
                    }}
                >
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={e => {
                            this.props.save(this.props.closeModal);
                        }}
                    >
                        Submit
                    </button>
                </div>
            </>
        );
    }
}

export default ProgressView;
