import React from 'react';
import PropTypes from 'prop-types';
import style from './style.css';
import DefaultHeader from '../NavBar/index.js';
// import { initialSettings } from './Settings';
// import { getBgStyles } from './helpers';

const Component = props => {
    const { showHeader, showFooter, footer } = props;
    const header = props.header || <DefaultHeader />;
    const role = window.cccisd.fortress.user.acting.role.handle;
    const isLearner = role === 'learner';

    return (
        <div className={style.componentWrapper}>
            {showHeader && !isLearner && <div>{header}</div>}
            <div className={style.body} data-cccisd-survey-area>
                {props.children}
            </div>
            {showFooter && footer && !isLearner && <div>{footer}</div>}
        </div>
    );
};

Component.propTypes = {
    header: PropTypes.node,
    footer: PropTypes.node,
    showHeader: PropTypes.bool,
    showFooter: PropTypes.bool,
    settings: PropTypes.object,
    children: PropTypes.node,
    isShowingLayoutBgColor: PropTypes.bool,
};

export default Component;
