import Component from './Component.js';
import thumbnail from './thumbnail.png';

export default {
    handle: 'eePageStyle',
    label: 'EE Page Style',
    component: Component,
    thumbnail,
    flowPlayerSettings: {
        background: 'solidColor',
        backgroundColor: '#fff',
    },
};
