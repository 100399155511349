import EmotionExplorer from 'cccisd-game-emotionexplorer';
import choiceDelay from 'cccisd-game-selee-choice-delay';
import {
    components as eeComponents,
    componentTemplates as eeComponentTemplates,
    playerTemplates as eePlayerTemplates,
} from 'cccisd-laravel-selwebee';

export default {
    components: [
        choiceDelay,
        eeComponents.AboutStudentMC,
        eeComponents.AboutStudentMatrix,
        eeComponents.Assistant,
        eeComponents.Intro,
        eeComponents.FT,
        eeComponents.FTIntro,
        EmotionExplorer,
    ],
    templates: {
        components: {
            multipleChoice: [
                eeComponentTemplates.CometAndBearMC,
                eeComponentTemplates.CometOrBearMC,
                eeComponentTemplates.SocialAwarenessMC,
                eeComponentTemplates.SocialPicMC,
                eeComponentTemplates.SocialTextMC,
            ],
            video: [eeComponentTemplates.NoButtonVideo],
        },
        players: {
            flow: [
                eePlayerTemplates.EE,
                eePlayerTemplates.Vignette,
                eePlayerTemplates.RocketGame,
                eePlayerTemplates.Video,
            ],
        },
    },
};
