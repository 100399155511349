import { useState, useEffect } from 'react';
import _omit from 'lodash/omit';
import _get from 'lodash/get';
import _union from 'lodash/union';
import { client } from 'cccisd-apollo';
import getBooksQuery from './getBooks.graphql';
import bookProgressQuery from './bookProgress.graphql';

const flowKeysToOmit = [
    'flowSettings',
    'isComplete',
    'isStarted',
    'progressBarGroup',
    'showIfOption',
    'surveySettings',
];

const Fortress = window.cccisd.fortress;

/* COPIED THIS HOOK FROM cccisd-laravel-assignment/src/components/navigations/useNavigation.js */
export default ({
    flowList,
    onFlowStarted = () => {},
    onFlowComplete = () => {},
    onComplete = () => {},
    onAssignmentComplete = () => {},
    onAlreadyComplete = () => {},
    allowRetake,
    mustTakeInOrder = false,
    enablePrintMode = false,
}) => {
    const isLearner = _get(Fortress, 'user.acting.role.handle', '') === 'learner';
    const [flowWhiteList, setFlowWhiteList] = useState(isLearner ? [] : flowList);
    const [isLoading, setIsLoading] = useState(true);
    const [completedFlows, setCompletedFlows] = useState([]);
    const [startedFlows, setStartedFlows] = useState([]);
    const [currentFlowHandle, setCurrentFlowHandle] = useState('');
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        async function getLearnerFlowWhiteList() {
            setIsLoading(true);
            let availableFlows = flowList;
            if (isLearner) {
                const response = await client.query({
                    query: getBooksQuery,
                    fetchPolicy: 'network-only',
                });

                const learnerSurveyWhiteList =
                    _get(response, 'data.groups.class.createdAssignmentPlan.session.settings.options', []) || [];

                availableFlows = flowList.filter(flow => {
                    return flow.showIfOption.some(surveyHandle => learnerSurveyWhiteList.includes(surveyHandle));
                });
            }

            setFlowWhiteList(availableFlows);
            setCompletedFlows(availableFlows.filter(item => item.isComplete).map(item => item.handle));
            setStartedFlows(availableFlows.filter(item => item.isStarted).map(item => item.handle));
            setIsLoading(false);
        }

        getLearnerFlowWhiteList();
    }, [flowList]);

    const currentFlowFromFlowList = flowWhiteList.find(item => item.handle === currentFlowHandle);

    useEffect(() => {
        async function checkOnAlreadyComplete() {
            if (!allowRetake && flowWhiteList.every(item => item.isComplete)) {
                await onAlreadyComplete();
            }
        }
        checkOnAlreadyComplete();
    }, [flowWhiteList]);

    useEffect(() => {
        async function handleFlowStarted() {
            setIsEditing(false);
            await onFlowStarted(currentFlowFromFlowList);
        }
        if (currentFlowHandle) {
            handleFlowStarted();
        }
    }, [currentFlowHandle]);

    async function checkBookProgress(flowHandle) {
        const resp = await client.query({
            query: bookProgressQuery,
            variables: {
                flowHandle,
            },
            fetchPolicy: 'network-only',
        });
        const isComplete = _get(resp, 'data.roles.anyRole.flowProgress.completed', false);
        if (isComplete) {
            completeCurrentFlow(flowHandle);
        }
    }

    const completeCurrentFlow = async flowHandle => {
        const flowToUse = flowHandle ? flowWhiteList.find(f => f.handle === flowHandle) : currentFlowFromFlowList;

        // This Promise ensures the nested FlowPlayer component
        // waits for this all to finish before unmounting
        return new Promise(async resolve => {
            setIsLoading(true);

            await onFlowComplete(flowToUse);

            const allFlowsAreAlreadyCompleted = completedFlows.length === flowWhiteList.length;

            const updatedCompletedFlows = _union(completedFlows, [flowToUse.handle]);
            setCompletedFlows(updatedCompletedFlows);

            const requiredFlows = flowWhiteList.filter(item => item.isRequired);
            if (requiredFlows.every(item => updatedCompletedFlows.includes(item.handle))) {
                await onAssignmentComplete();
            }

            if (updatedCompletedFlows.length === flowWhiteList.length && !allFlowsAreAlreadyCompleted) {
                await onComplete();
            }

            setCurrentFlowHandle('');
            setIsLoading(false);
            return resolve();
        });
    };

    const navItems = flowWhiteList.map(flow => {
        let isCompleted = false;
        let isAllowed = !mustTakeInOrder;
        let isCurrent = false;
        let isNew = false;
        const isStarted = startedFlows.includes(flow.handle);

        if (flow.handle === currentFlowHandle) {
            isCurrent = true;
        }
        if (completedFlows.includes(flow.handle)) {
            isCompleted = true;
            isAllowed = true;
        }
        if (isStarted) {
            isAllowed = true;
        }
        if (!isCurrent && !isCompleted && !isStarted) {
            isNew = true;
        }

        return {
            ..._omit(flow, flowKeysToOmit),
            title: _get(flow, 'flowSettings.playerTemplateSettings.title', flow.handle),
            isCompleted,
            isAllowed,
            isCurrent,
            isNew,
            setCurrent: isAllowed ? () => setCurrentFlowHandle(flow.handle) : null,
        };
    });

    const currentFlowProps = (() => {
        if (!currentFlowHandle) {
            return null;
        }

        const currentFlowIndex = navItems.findIndex(item => item.handle === currentFlowHandle);
        const currentFlow = navItems[currentFlowIndex];

        return {
            ..._omit(currentFlow, flowKeysToOmit),
            onComplete: () =>
                // This promise makes sure all of this finishes before moving on to next flow
                new Promise(async resolve => {
                    await completeCurrentFlow();
                    return resolve();
                }),
            goBack: () => {
                setCurrentFlowHandle('');
            },
            ...(currentFlow.isCompleted && { pageFirstElementId: 0 }),
            ...(currentFlow.isCompleted && enablePrintMode && { isPrintMode: !isEditing }),
        };
    })();

    return {
        navItems,
        checkBookProgress,
        currentFlowProps,
        setCurrentFlowHandle,
        isLoading,
        ...(currentFlowProps &&
            currentFlowProps.isCompleted &&
            enablePrintMode && { isPrintMode: !isEditing, isEditing, setIsEditing }),
    };
};
