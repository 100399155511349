import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import _get from 'lodash/get';
import store from 'store';
import { client } from 'cccisd-apollo';
import ClickButton from 'cccisd-click-button';
import { Formik, Form, Field, CccisdInput } from 'cccisd-formik';
import IconWrong from 'cccisd-icons/cancel-circle2';
import Loader from 'cccisd-loader';
import { Html } from 'cccisd-wysiwyg';
import style from './style.css';
import classDataQuery from './getClassData.graphql';
import axios from 'cccisd-axios';
import notification from 'cccisd-notification';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import safari from './assets/Groups0001.png';
import bees from './assets/Groups0002.png';
import circus from './assets/Groups0003.png';
import logo from './assets/minimal_logo.svg';

const Boilerplate = window.cccisd.boilerplate;
const Fortress = window.cccisd.fortress;

const StudentLogin = () => {
    if (Fortress.auth()) {
        return <DeploymentPlayer deploymentHandle="main" />;
    }
    const { classCode } = useParams();
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [deploymentError, setDeploymentError] = useState('');
    const [studyNameError, setStudyNameError] = useState('');
    const [classGroupId, setClassGroupId] = useState(null);
    store.remove('cccisd_logout_redirect_to');

    useEffect(() => {
        async function fetchDeployment() {
            const resp = await client.query({
                query: classDataQuery,
                variables: {
                    code: classCode,
                },
                fetchPolicy: 'network-only',
            });

            const groupId = _get(resp, 'data.groups.class.group.groupId', null) || null;
            if (!groupId) {
                setDeploymentError('This is not the correct link. Please double check the URL.');
                setIsInitialLoad(false);
                return;
            }
            setClassGroupId(groupId);
            setIsInitialLoad(false);
        }

        fetchDeployment();
    }, []);

    async function onSubmit({ passcode }) {
        setStudyNameError('');
        passcode = passcode.toLowerCase();
        if (!passcode) {
            setStudyNameError('Please provide a code');
        }

        const response = await axios.post(Boilerplate.route('api.app.checkStudentCode'), {
            passcode,
            classId: classGroupId,
        });

        const valid = _get(response, 'data.data.valid', null);
        const username = _get(response, 'data.data.username', null);

        if (!valid) {
            setStudyNameError('Wrong code. Please try again.');
        } else {
            login(username);
        }
    }

    async function login(username) {
        const response = await axios.post(Boilerplate.route('api.nexus.login'), {
            username,
            password: username,
        });

        const status = _get(response, 'data.status', 'unsuccessful');

        if (status === 'success') {
            store.set('cccisd_logout_redirect_to', `/student/${classCode}`);
            // force page refresh now that they're logged in
            window.location = window.location.href;
        } else {
            notification({
                message: 'Login unsuccessful, check your code and try again.',
                type: 'danger',
            });
        }
    }

    if (isInitialLoad) {
        return (
            <div className={style.initialLoader}>
                <Loader loading type="inline" />
            </div>
        );
    }

    return (
        <div className={style.main}>
            <img src={safari} className={style.safari} alt="" />
            <img src={circus} className={style.circus} alt="" />
            <img src={bees} className={style.bees} alt="" />
            <img src={logo} className={style.logo} alt="" />
            <div className={style.panel}>
                {deploymentError && (
                    <p className={`text-danger ${style.errorMessage}`}>
                        <Html content={deploymentError} />
                    </p>
                )}
                {!deploymentError && (
                    <Formik onSubmit={onSubmit}>
                        {({ handleSubmit, isSubmitting }) => (
                            <Form>
                                <div className={style.field}>
                                    <Field name="passcode">
                                        {({ field, form }) => {
                                            function onChangeField(val) {
                                                setStudyNameError('');
                                                field.onChange(val);
                                            }

                                            return (
                                                <CccisdInput
                                                    form={form}
                                                    field={{ ...field, onChange: onChangeField }}
                                                    autoFocus
                                                    label="Your Passcode:"
                                                />
                                            );
                                        }}
                                    </Field>
                                </div>
                                <ClickButton
                                    className="btn btn-lg btn-primary"
                                    type="submit"
                                    title="Start"
                                    onClick={handleSubmit}
                                    isLoading={isSubmitting}
                                />
                                {studyNameError && (
                                    <p className={`text-danger ${style.studyNameError}`}>
                                        <IconWrong />
                                        &nbsp;&nbsp;{studyNameError}
                                    </p>
                                )}
                            </Form>
                        )}
                    </Formik>
                )}
            </div>
        </div>
    );
};

export default StudentLogin;
